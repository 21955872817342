@use 'custom-properties';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-light), 1);
	font-family: 'HajperSans Regular', sans-serif;
}

body {
	background-color: rgba(var(--primary-accent-blue-dark), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-blue), 1);

		h2 {
			color: rgba(var(--font-light), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-green), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/bonus-active

.bonus-active {
	.offer-tag {
		.support-title {
			color: rgba(var(--indigo-100), 1);
		}
	}
}

.shop-detail__description .offer-tag svg {
	fill: rgba(var(--primary-accent-green), 1);
}

.progress {
	svg {
		path {
			stroke: rgba(var(--primary-accent-blue), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-green), 1);
	color: rgba(var(--font-dark), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-blue-light), 1);
		color: rgba(var(--font-disabled), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-dark), 1);

		&:disabled {
			color: rgba(var(--base-08), 1);
		}
	}

	&.btn--tertiary {
		background: none;
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--ghost {
		color: rgba(var(--font-light), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: black;
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-blue-light), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		color: rgba(var(--base-01), 1);
	}

	&.btn--logout {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		border-radius: 3px;
		color: rgba(var(--base-01), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-green), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary), 1);
	}
}

.closing-reasons {
	.link-list__item {
		font: var(--h4-bold);
	}

	.item-border {
		border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);
	}
}

// components/collapse

.welcome-menu {
	.welcome-screen-collapse {
		background-color: rgba(var(--primary-accent-blue-light), 1);

		p {
			color: rgba(var(--base-01), 1);
		}

		.collapse__continue-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		tr:not(:last-of-type) {
			border-bottom: 1px solid rgba(var(--primary-accent-blue), 1);
		}
	}
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(138%) sepia(45) hue-rotate(61deg) saturate(61);
	}
}

// components/filters

.user-options {
	background-color: rgba(var(--primary-accent-blue), 1);
	border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--primary-accent-blue), 1);
		}

		&:checked {
			+ .filters__filter {
				color: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-green), 1);
		height: 4px;
	}
}

// components/flags
// components/form

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #123156 !important;
	-webkit-text-fill-color: #f6f8fc !important;
}

::-webkit-calendar-picker-indicator {
	filter: invert(84%) sepia(14%) saturate(2136%) hue-rotate(79deg) brightness(105%) contrast(92%);
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);
		color: rgba(var(--base-01), 1);

		&:focus {
			color: rgba(var(--primary-accent-green), 1);
		}
	}

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	.slider-input {
		background-color: rgba(var(--primary-accent-blue-light), 1);
	}
}

.slider-icon {
	.icon--sprite {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--font-supportive), 1);
}

::-webkit-input-placeholder {
	// color: rgba(var(--base-01), 1);
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	// color: rgba(var(--base-01), 1);
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	// color: rgba(var(--base-01), 1);
	color: rgba(var(--font-supportive), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/game-category
// components/game-item

.item__urgent {
	background-color: rgba(var(--primary-accent-blue-dark), 1);

	* {
		color: rgba(var(--base-01), 1);
	}
}

// components/game-list

.item__footer {
	border-top: 1px solid rgba(var(--primary-accent-blue-light), 1);

	.item__love {
		border-right: 1px solid rgba(var(--primary-accent-blue-light), 1);
	}
}

.item__love {
	&.game-item__love--selected {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.item__love-counter {
			color: rgba(var(--primary-accent-green), 1);
		}
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);
}

.game-item__jackpot {
	background-color: rgba(var(--base-01), 1);
	color: rgba(var(--base-12), 1);
}

// components/header

.page-head {
	background-color: rgba(var(--primary-accent-blue), 1);

	.account-icon-mobile {
		.icon--sprite {
			fill: var(--balance-icons, rgba(var(--base-02), 1));
		}
	}

	.page-head--bankid-email {
		.page-head__back {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	&.u-bg-trans {
		.icon--sprite {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

.bonus-step__hydration-spinner-bg-color {
	background-color: rgba(var(--secondary), 1);
}

.sidebar-header__icon--filter {
	fill: rgba(var(--primary-accent-green), 1);
}

.svg-logo {
	fill: rgba(var(--primary-accent-green), 1);
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--primary-accent-blue-dark), 1);

		&--rounded {
			border-radius: 3px;
		}

		&.disabled {
			svg {
				fill: rgba(var(--base-07), 1);
			}

			.menu__main-title,
			.link-list__preamble,
			.link-list__continue-icon {
				color: rgba(var(--base-07), 1);
			}
		}

		svg {
			fill: rgba(var(--primary-accent-green-light), 1);
		}

		.menu__main-title {
			color: rgba(var(--base-01), 1);
		}

		.link-list__preamble {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.link-list__more-icon {
			height: 20px;
			width: 20px;
		}

		.link-list__value {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--primary-accent-green), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--base-04), 1);
		}
	}
}

.deposit-limit-link-list {
	.valid-limits-icon {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/list-options

.list-options {
	background-color: rgba(var(--primary-accent-blue), 1);

	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);
		}
	}
}

// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

.my-notification-item-wrapper .my-notification-details .my-notification-title-bar .my-notification-title {
	color: rgba(var(--font-light), 1);
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-01), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-01), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}
// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-10), 1);

		.binary-question__head {
			.binary-question__main-title {
				color: rgba(var(--font-light), 1);
			}

			.binary-question__p {
				color: rgba(var(--font-supportive), 1);
				font: var(--label-regular);
			}

			.binary-question__main-title {
				font: var(--h4-bold);
				margin-bottom: 4px;
			}
		}

		.binary-question__button-container {
			.binary-question__yes,
			.binary-question__no,
			.binary-question__third {
				border-top: 1px solid rgba(var(--base-11), 1);
				color: rgba(var(--font-light), 1);
				font: var(--h4-bold);
			}

			.binary-question__yes {
				border-left: 1px solid rgba(var(--base-11), 1);
			}
		}
	}

	.input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		color: rgba(var(--base-01), 1);
	}

	.notify .notify--is-open {
		background-color: rgba(var(--primary-accent-blue), 0.85);
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--base-11), 1);

	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--success), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX

// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.85);
	}
}

// COOKIE CONSENT

// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-blue-dark), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

button.payment-btn-secondary {
	border: 1px solid rgba(var(--primary), 1);
	color: rgba(var(--primary), 1);
}

.js-notify__button-wrapper--failed {
	.btn.btn--secondary {
		margin-bottom: 12px;
	}

	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	background-color: rgba(var(--primary-accent-blue), 0.72);

	&.reality-check-confirm {
		background-color: rgba(var(--primary-accent-blue), 1) !important;
		background-image: none;
	}
}

.option-box {
	background-color: rgba(var(--primary-accent-blue), 0.72);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--base-11), 0.5);
	}

	.btn--secondary {
		background-color: rgba(var(--base-09), 1);
		color: rgba(var(--primary-accent-blue-dark), 1);
	}

	.notify__p {
		color: rgba(var(--primary-accent-blue-dark), 1);
	}

	.user-options {
		background-color: transparent;

		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--base-09), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-green), 1);
					}
				}
			}
		}
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--primary-accent-blue), 1);

	.quick-deposit-content {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/quick-deposit payment methods mobile content

.payment-method-qd-cc-nr {
	color: rgba(var(--font-supportive), 1);
}

// components/scroll-page-head

header.page-head {
	&.page-head--opac {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

// components/select

.custom-select {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);

	select {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		color: white !important;
	}

	option {
		color: rgba(var(--font-light), 1);
	}

	select.placeholder-active {
		color: rgba(var(--base-01), 1);
	}

	&::after {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/spinner
// components/support-nav

.support-nav {
	&.support-nav--is-open {
		background-color: rgba(var(--base-11), 0.85);

		.btn--secondary {
			background-color: rgba(var(--primary-accent-blue), 1);
			color: rgba(var(--font-light), 1);
		}
	}
}

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		.btn--secondary {
			border: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
			color: rgba(var(--base-02), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);

	&.u-text-r,
	p {
		color: rgba(var(--font-supportive), 1);
	}

	&:first-of-type {
		font: var(--label-bold);
	}
}

// components/tabs

/*
.bottom-wrapper {
	background-color: rgba(var(--primary-accent-blue), 1);
	border-top: 1px solid rgba(var(--primary-accent-blue-light), 1);
	&.signup--open,
	&.log-in--open {
		background-color: rgba(var(--primary-accent-blue), 1);
		border-top: 0;
	}
}

.tabs-bar-container {
	background-color: rgba(var(--primary-accent-blue), 1);
	border-top: 1px solid rgba(var(--primary-accent-blue-light), 1);
}
*/

.tab {
	* {
		color: rgba(var(--base-01), 1);
	}

	[aria-selected='true'] {
		* {
			color: rgba(var(--primary-accent-green), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/winners
// layouts/bundles
// layouts/deposit

.bonus-item__image-wrapper {
	background-color: var(--primary-accent-green-light);
}

.learn-more-button-bonus {
	&.pill-bonus {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
	}
}

// layouts/footer

.paragraph-footer {
	font: var(--label-footer-paragraph);
}

.paragraph-footer,
.paragraph-footer span *,
.footer-legal-content {
	color: rgba(var(--font-supportive), 1);

	a {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.paragraph-footer span h2 {
	padding-bottom: 6px;
}

.footer-legal-content {
	small {
		color: rgba(var(--footer-text), 1);
	}
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

/* special fix as custom prop --footer-links don't
 apply the bold rules due to the fonts used here */
.footer-links-grid ul li a {
	font-weight: 700;
}

// layouts/info-box

.info-box {
	background-color: rgba(var(--primary-accent-blue-light), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--base-01), 1);
	}
}

// layouts/log-in
// layouts/modal-view

.modal-view {
	background-color: rgba(var(--primary-accent-blue), 1);

	.page-head {
		background-color: inherit;
	}

	.at-icon-close {
		.icon--sprite {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--primary-accent-blue), 1);

	.limit-dialog__back-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// Mentor

.game-types {
	td {
		border-bottom-color: rgba(var(--primary-accent-blue-light), 1);
	}
}

// layouts/my-limits

.my-limits-menu {
	.limits-notification-wrapper {
		background-color: rgba(var(--primary-accent-blue-dark), 1);

		.limits-notification-icon {
			fill: rgba(var(--primary-accent-green-light), 1);
		}

		* {
			color: rgba(var(--base-01), 1);
		}

		small {
			color: rgba(var(--font-supportive), 1);
		}

		.icon-close--small {
			.icon--sprite {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.dots-container {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

// layouts/off-canvas

.global-menu,
.account-menu {
	.page-head {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

// layouts/off-canvas-account

.last-login-timer {
	&--text,
	&--status {
		color: rgba(var(--font-supportive), 1);
	}
}

.user-balance {
	.user-balance__title {
		color: rgba(var(--font-supportive), 1);
	}

	.user-balance__sum {
		color: rgba(var(--base-01), 1);
	}
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--font-light), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/payment
// layouts/pusher
// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		svg,
		.icon--sprite {
			fill: rgba(var(--base-01), 1);
		}
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.js-notify__button-wrapper--failed {
	.btn.btn--secondary {
		&:first-child {
			border-radius: var(--button-border-radius);
		}
	}
}

.result-button-wrapper {
	&.js-notify__button-wrapper--failed {
		.btn--secondary {
			background-color: rgba(var(--primary-accent-blue-dark), 1);

			.verify-mail-support-btn span {
				color: rgba(var(--font-light), 1);
			}
		}
	}
}

// layouts/risk

.risk-tag {
	background-color: rgba(var(--primary-accent-green), 1);
}

// layouts/shop

.points__svg {
	fill: rgba(var(--primary-accent-green), 1);

	text {
		fill: rgba(var(--base-01), 1);
	}
}

// layouts/signup
// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	.adaptable-header--pinned {
		--page-head-title: transparent;

		background-color: rgba(var(--primary-accent-blue), 1);
		border-bottom: transparent;
	}

	.adaptable-header--unfixed {
		--page-head-title: rgba(var(--font-light), 1);
		--page-head-link: rgba(var(--primary-accent-green), 1);
		--page-head-icon: rgba(var(--primary-accent-green), 1);
		--hamburger-menu: rgba(var(--primary-accent-green), 1);

		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

.static-text .svg-logo {
	fill: rgba(var(--primary-accent-green), 1);
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--primary-accent-green), 1);
	text-decoration: underline;
}

// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--base-11), 1);
}

.transaction-modal {
	background-color: rgba(var(--primary-accent-blue), 1);
}

.transaction-modal {
	.pill {
		&.disable {
			.menu__main-title {
				color: rgba(var(--primary-accent-green-light), 1);
			}
		}
	}
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);

	&::after {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

// layouts/welcome-screen

.welcome-menu {
	background: rgba(var(--primary-accent-blue), 1);

	.accordion {
		.accordion__link,
		.accordion__content {
			background-color: rgba(var(--primary-accent-blue-light), 1);

			tr {
				border-bottom: solid 1px rgba(var(--primary-accent-blue), 1);
			}
		}

		.accordion__link {
			&::before,
			&::after {
				background-color: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.welcome-screen-popup {
	section {
		svg {
			fill: rgba(var(--primary-accent-green-light), 1);
		}
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);

	.pending-withdrawal__action {
		&.js-cancel-withdraw-btn svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

// layouts/SEO content

.seo-content {
	.seo-content__color,
	* {
		h1,
		h2,
		h3,
		h4 {
			color: rgba(var(--base-01), 1);
			//font-style: italic;
		}

		p {
			color: rgba(var(--font-supportive), 1);
			//font-weight: 600;
		}
	}

	a {
		color: rgba(var(--primary-accent-green), 1);
	}
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--primary-accent-blue), 1);
}

.u-text-grey {
	color: rgba(var(--font-supportive), 1);
}

.u-divider {
	border-color: rgba(var(--primary-accent-blue-light), 1);
}

// swish-logo

.swish-logo {
	.text-color {
		fill: rgba(var(--base-01), 1);
	}
}

//support/zendesk

.verify-mail-support-btn {
	span {
		color: rgba(var(--font-dark), 1);
	}
}

// Risk-profile

.risk-profile__description {
	.detail-view-header {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

// components/payment/link-list
// Payment-provider tags

.payment-tag {
	color: rgba(var(--font-dark), 1);
	font-size: 10px;
	font-weight: 700;

	&__popular {
		background-color: rgba(var(--primary-accent-pink), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-green), 1);
	}
}
